// libs
import { Amplify, Auth, API } from "aws-amplify";

// defs
import * as graphQlQueries from "./schema/queries";
import * as graphQlMutations from "./schema/mutations";

const getClient = (): typeof API => {
    return API;
};

export const configureAmplify = async (settingsData: any): Promise<string> => {
    //@ts-ignore
    const windowLocation = window.location;
    Amplify.configure({
        aws_project_region: settingsData?.appsync?.region,
        aws_appsync_region: settingsData?.appsync?.region,
        aws_appsync_graphqlEndpoint: settingsData?.appsync?.graphqlEndpoint,
        aws_appsync_authenticationType: settingsData?.appsync?.authenticationType,
        aws_appsync_apiKey: settingsData?.appsync?.apiKey,
        Auth: {
            identityPoolId: settingsData?.cognito?.identityPoolId,
            userPoolId: settingsData?.cognito?.userPoolId,
            userPoolWebClientId: settingsData?.cognito?.userPoolClientId,
            region: settingsData?.cognito?.region,
            oauth: {
                domain: settingsData?.cognito?.loginDomain,
                scope: ["openid"],
                responseType: "code",
                redirectSignIn: "https://" + windowLocation.host,
                redirectSignOut: "https://" + windowLocation.host,
            },
            /*"Cognito": {
                "identityPoolId": settingsData?.cognito?.identityPoolId,
                "userPoolId": settingsData?.cognito?.userPoolId,
                "userPoolClientId": settingsData?.cognito?.userPoolClientId,
                "region": settingsData?.cognito?.region,
                "loginWith": {
                    "oauth": {
                        "domain": settingsData?.cognito?.loginDomain,
                        "scope": ["openid"],
                        "responseType": "code",
                        "providers": [
                            {
                                "custom": settingsData?.cognito?.providerName
                            }
                        ]
                    },
                    "username": "true"
                }
            }*/
        },
    });
    return await getCurrentUser(settingsData?.cognito?.providerName);
};

const getCurrentUser = async (providerName: string): Promise<string> => {
    try {
        return await Auth.currentAuthenticatedUser();
    } catch (e) {
        await Auth.federatedSignIn({
            customProvider: providerName,
        });
    }
};

export { graphQlMutations, getCurrentUser, getClient, graphQlQueries };
